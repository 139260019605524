<template>
  <div class="login-container">
    <el-row>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <el-form
          ref="form"
          class="login-form"
          :model="loginForm"
          :rules="loginRules"
          :hide-required-asterisk="true"
        >
          <div class="title-tips">注册账号</div>
          <!-- 注册账号 -->
          <div>
            <el-form-item prop="userName" label-position="top" label="电子邮件">
              <el-input
                v-model.trim="loginForm.userName"
                onfocus="this.removeAttribute('readonly');"
                placeholder="请输入邮件"
                type="text"
                clearable
              >
                <!-- <template #prefix>
                  <span class="svg-container">
                    <i class="el-icon-message"></i>
                  </span>
                </template> -->
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="输入手机号">
              <el-input
                ref="password"
                v-model="loginForm.password"
                onfocus="this.removeAttribute('readonly');"
                placeholder="请输入手机号"
                type="password"
                clearable
              >
                <!-- <template #prefix>
                  <span class="svg-container">
                    <i class="el-icon-phone" />
                  </span>
                </template> -->
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="设置密码">
              <el-input
                show-password
                ref="password"
                v-model="loginForm.password"
                onfocus="this.removeAttribute('readonly');"
                placeholder="请输入密码"
                type="password"
                clearable
              >
                <!-- <template #prefix>
                  <span class="svg-container">
                    <i class="el-icon-lock"></i>
                  </span>
                </template> -->
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="再次输入密码">
              <el-input
                show-password
                ref="password"
                v-model="loginForm.password"
                onfocus="this.removeAttribute('readonly');"
                placeholder="请再次输入新密码"
                type="password"
                clearable
              >
                <!-- <template #prefix>
                  <span class="svg-container">
                    <i class="el-icon-lock"></i>
                  </span>
                </template> -->
              </el-input>
            </el-form-item>
            <el-form-item prop="SMSCode">
              <div class="label">手机验证码</div>
              <div class="tow-input">
                <el-input
                  ref="password"
                  v-model="loginForm.password"
                  onfocus="this.removeAttribute('readonly');"
                  placeholder="请输入验证码"
                  type="password"
                  clearable
                >
                  <!-- <template #prefix>
                    <span class="svg-container">
                      <i class="el-icon-s-comment" />
                    </span>
                  </template> -->
                </el-input>
                <el-button
                  class="send-code"
                  :disabled="timeNum > 0"
                  size="mini"
                  type="text"
                  @click="handleGetCode"
                >
                  {{ timeNum > 0 ? `重新发送(${timeNum}s)` : "发送验证码" }}
                </el-button>
              </div>
            </el-form-item>
            <div class="mb20">
              <el-checkbox v-model="checked"></el-checkbox
              >&nbsp;我同意本网站的&nbsp;<span class="base-color"
                >条款和条件</span
              >
            </div>
            <div class="login-box-btns">
              <el-button :loading="loading" @click="handleLogin">
                确认
              </el-button>
            </div>
            <div class="login-bottom">
              <div>
                <span>已有账户?&nbsp;&nbsp;</span>
                <span class="base-color" @click="handleToLogin">快速登录</span>
              </div>
            </div>
          </div>
        </el-form>
      </el-col>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if ("" === value) callback(new Error("用户名不能为空"));
      else callback();
    };
    const validatePassword = (rule, value, callback) => {
      if ("" === value) callback(new Error("密码不能为空"));
      // if (!isPassword(value)) callback(new Error('密码不能为空'))
      else callback();
    };
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    const validateTwoPwd = (rule, value, callback) => {
      if ("" === value) {
        callback(new Error("请二次确认密码"));
      } else if (this.changeForm.loginPass != value) {
        callback(new Error("请保持两次密码一致"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if ("" === value) callback(new Error("手机号不能为空"));
      else if (
        !/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
          value
        )
      ) {
        callback(new Error("请输入正确格式的手机号"));
      } else callback();
    };
    return {
      checked: false,
      loginForm: {
        userName: "",
        password: "",
        verifKey: "",
        verifCode: "",
      },
      loginRules: {
        userName: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: validateUsername,
          },
        ],
        password: [
          { required: true, trigger: "change", validator: validatePassword },
        ],
        verifCode: [
          { required: true, trigger: "blur", validator: validateVerifycode },
        ],
      },
      passwordType: "password",
      loading: false,
      code: "",
      timer: null,
      timeNum: 0,
    };
  },
  computed: {},
  watch: {
    // $route: {
    //   handler(route) {
    //     this.redirect = (route.query && route.query.redirect) || "/";
    //   },
    //   immediate: true,
    // },
  },
  created() {},
  mounted() {},
  methods: {
    handleToLogin() {
      this.$router.push("/login");
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    handleLogin() {
      this.$refs.form.validate(async (valid) => {
        if (valid)
          try {
            //     this.loading = true;
            //     await this.login({
            //       loginName: this.loginForm.userName,
            //       loginPass: md5(this.loginForm.password),
            //       verifKey: this.loginForm.verifKey,
            //       verifCode: this.loginForm.verifCode,
            //     }).catch(() => {
            //       this.refreshCode();
            //     });
            //     this.loading = false;
            //     if (this.companyName) {
            //       this.$router.push({
            //         path: "/detail",
            //         query: {
            //           companyName: this.companyName,
            //         },
            //       });
            //     } else {
            //       this.$router.push("/index");
            //     }
          } finally {
            this.loading = false;
          }
      });
      // this.loading = true
      // console.log(this.loginForm)
      // login({
      //   loginName: this.loginForm.userName,
      //   loginPass: md5(this.loginForm.password),
      // })
      //   .then(() => {})
      //   .finally(() => {
      //     this.loading = false
      //   })
    },

    // 切换验证码
    refreshCode() {
      // const params = {
      //   width: 86,
      //   height: 37,
      // }
    },
    handleChange(tab) {
      this.activeName = tab.name;
      this.refreshCode();
    },
    handleSumbitLogin(res) {
      if (res.userStatus === "0") {
        Object.assign(this.activeForm, res);
        this.dialogActive.visible = true;
      } else {
        let url = "/portal/vab1/notice";
        this.$router.push({
          path: url,
        });
      }
    },
    handleGetCode() {
      // if (!this.changeForm.phone) {
      //   this.$message.warning("请输入电话号码");
      // } else {
      // }
      // } else if (!this.loginForm.mobilePhone) {
      //   this.$message.warning('请输入手机号')
      // } else if (!this.loginForm.email) {
      //   this.$message.warning('请输入邮箱')
      // }
      // else {
      // const params = {
      //   userName: this.loginForm.userName,
      //   mobilePhone: this.loginForm.mobilePhone,
      //   email: this.loginForm.email,
      // }
      // api_SMSCode(params).then((res) => {
      //   if (res.code === 200) {
      // this.$message.success(`已发送，请注意查收`)
      //     this.loginForm.codeKey = res.data.codeKey
      this.timeNum = 60;
      this.timer = setInterval(() => {
        this.timeNum--;
        if (!this.timeNum) {
          clearInterval(this.timer);
        }
      }, 1000);
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
      // }
    },
    changePassword() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          console.log(this.changeForm);
        }
      });
    },
    handleCode(type) {},
  },
};
</script>

<style lang="less" scoped>
.login-container {
  height: 100vh;
  width: 100%;
  background: url("../../assets/imgs/bg.png") center no-repeat;
  background-size: 100% 100%;
}
.login-form {
  position: relative;
  max-width: 100%;
  padding: 2.5vh 3.5vh;
  margin: calc((100vh - 700px) / 2) 9vw;
  // background-color: #fff;
  border-radius: 8px;
  width: 540px;

  .title-tips {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #31363e;
    z-index: 66666;
    // position: relative;
    // &::after{
    //   width: 147px;
    //   height: 19px;
    // background: rgba(49, 54, 100, .7);
    // border-radius: 10px;
    // content: '';
    // position: absolute;
    // top: 44px;
    // left: 16px;
    // }
  }

  // i {
  //   position: absolute;
  //   top: 8px;
  //   left: 5px;
  //   font-size: 16px;
  //   color: #d7dee3;
  //   cursor: pointer;
  //   user-select: none;
  // }

  ::v-deep {
    .el-form-item {
      padding-right: 0;
      margin: 10px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
    }
    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }

    .el-input {
      box-sizing: border-box;

      input {
        height: 48px;
        // padding-left: 35px;
        line-height: 58px;
        background: #fff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        border: 2px solid #eef0f6;
      }
    }
  }
  .code-btn {
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 28%;
    height: 48px;
    margin: 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border-radius: 0;
    background-color: #353a42;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      color: #999;
    }
  }
  .login-box-btns {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    .el-button {
      flex: 1;
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
      border-color: #e2e2e2;
      background: #e2e2e2;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // &:hover {
      //   opacity: 0.8;
      //   color: #fff;
      // }
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .svg-container {
    i {
      line-height: 30px;
    }
  }
}
.tow-input {
  display: flex;
  ::v-deep {
    .el-input {
      width: 60% !important;
    }
  }
  .el-button.is-disabled.el-button--text{
    background-color: #999;
    color: #fff !important;
  }
  .send-code {
    width: 32%;
    background: #353a42;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    margin-left: 8%;
     &:hover {
      cursor: pointer;
    }
    &:disabled {
      color: #999;
    }
  }
}
.label {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #b9bfca;
}

.login-bottom {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a6b5c4;
  text-align: right;
}
</style>
