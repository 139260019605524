<template>
  <div class="header">
    <div class="personal">
      <div class="text">
        <p class="name1">{{ userInfo.userName }}</p>
      </div>
      <el-dropdown @command="handleCommand">
        <img
          src="@/assets/imgs/icon_touxiang@2x.png"
          width="60px"
          class="ml20"
        />
        <img
          width="25px"
          class="img2"
          src="@/assets/imgs/icon_touxiang_shezhi@2x.png"
          alt=""
        />
        <el-dropdown-menu class="dropdownPop" slot="dropdown">
          <!-- <el-dropdown-item command="personal"
            ><img
              src="@/assets/imgs/icon_gerenziliao@2x.png"
              width="28px"
              style="margin: 0px 5px"
            />个人资料</el-dropdown-item
          > -->
          <el-dropdown-item command="logout">
            <img
              src="@/assets/imgs/icon_tuichu@2x.png"
              width="20px"
              height="20px"
              style="margin: 0px 10px"
            />{{translateTitle('退出')}}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- <div class="massage">
      <el-badge :value="2">
        <i class="el-icon-bell fs20"></i>
      </el-badge>
    </div> -->
    <div>
      <el-dropdown @command="changeType">
        <div class="lange">
          <img :src="require('@/assets/imgs/icon_fanyi.png')" class="mr10" />
          {{ $i18n.locale }}
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="CN">中文简体</el-dropdown-item>
              <el-dropdown-item command="EN">English</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { translateTitle } from "@/utils/i18n";
import { loginOut } from "../../api/login";
// import getPageTitle from '@/utils/pageTitle'
import local from "@/utils/local";
export default {
  inject: ["reload"],
  data() {
    return {
      info: {},
    };
  },
  computed: {
    userInfo() {
      return local.get("userInfo");
    },
  },
  created() {},
  methods: {
    translateTitle,
    ...mapActions({
      changeLanguage: "settings/changeLanguage",
    }),
    // 下拉菜单
    async handleCommand(command) {
      if (command === "personal") {
        this.$router.push("/account/personal");
      } else if (command === "logout") {
        let res = await loginOut();
        if (res.data.code == 200) {
          this.$message({
            message:this.translateTitle('退出成功'),
            type: "success",
          });
          local.clear();
          this.$router.push("/login");
        }
      }
    },
    changeType(command) {
      this.changeLanguage(command);
      this.$i18n.locale = command;

      this.reload();
      // document.title = getPageTitle(this.$route.meta.title)
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  //   padding: 20px;
  .personal {
    display: flex;
    align-items: center;
    margin-left: 30px;
    .text {
      text-align: right;
      font-family: DIN;
      font-weight: bold;
      font-size: 14px;
      .name1 {
        color: #31363e;
        margin-bottom: 5px;
      }
      .name2 {
        color: #b9bfca;
      }
    }
    ::v-deep {
      .el-dropdown {
        display: flex;
        align-items: center;
      }
    }
  }
  .massage {
    padding: 0 10px;
  }
  .lange {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 42px;
    background: #f8fafb;
    border: 1px solid #e2eaee;
    border-radius: 10px;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    font-family: DIN;
    font-weight: bold;
    color: #31363e;
  }
}
</style>
