<template>
  <!-- 弹窗 -->
  <el-dialog
    append-to-body
    :width="width"
    v-if="visible"
    :visible="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="onClose"
  >
    <h3 slot="title" class="dialog-title" v-if="header">
      <span>{{ title }}</span>
    </h3>

    <!-- 内容 -->
    <div>
      <slot></slot>
    </div>

    <div slot="footer" v-if="footer">
      <el-button
        v-if="closeBtn"
        :icon="closeIcon"
        :disabled="closeDisabled"
        @click="onClose"
      >
        {{ closeText }}
      </el-button>
      <el-button
        v-if="sureBtn"
        :loading="btnloading"
        :icon="sureIcon"
        class="sure-btn"
        :disabled="sureDisabled"
        @click="onSure"
      >
        {{ sureText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogPop",

  props: {
    // 宽度
    width: {
      type: String,
      default: "60vw",
    },
    // 显示/隐藏
    visible: {
      type: Boolean,
      default: false,
    },
    // 按钮 加载中
    btnloading: {
      type: Boolean,
      default: false,
    },
    // 头部文字
    title: {
      type: String,
      default: "提示",
    },
    // 是否展示确认按钮
    sureBtn: {
      type: Boolean,
      default: true,
    },
    // 确认按钮 禁用
    sureDisabled: {
      type: Boolean,
      default: false,
    },
    // 确认按钮 图标
    sureIcon: {
      type: String,
      default: "",
    },
    // 确认按钮 文字
    sureText: {
      type: String,
      default: "确认",
    },
    // 是否展示取消按钮
    closeBtn: {
      type: Boolean,
      default: true,
    },
    // 取消按钮 禁用
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    // 取消按钮 图标
    closeIcon: {
      type: String,
      default: "",
    },
    // 取消按钮 文字
    closeText: {
      type: String,
      default: "取消",
    },
    // 是否展示全屏按钮
    showfullscreen: {
      type: Boolean,
      default: true,
    },
    // 默认是否全屏
    fullscreen: {
      type: Boolean,
      default: false,
    },
    // 是否展示footer内容
    footer: {
      type: Boolean,
      default: true,
    },
    //是否展示头部
    header: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  created() {},

  methods: {
    // 取消/关闭
    onClose() {
      this.$emit("onClose");
    },

    // 确定
    onSure() {
      this.$emit("onSure");
    },
  },
};
</script>
<style scoped="scoped" lang="less">
.dialog-title {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  &::after {
    width: 80px;
    height: 4px;
    background: #f6d655;
    content: "";
    position: absolute;
    top: 56px;
    left: 22px;
  }
}
::v-deep {
  .el-dialog__body {
    padding: 30px 20px;
  }
  .el-button:hover {
    border: 1px solid #ddd;
    color: #606266;
  }
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #000;
  }
}
.sure-btn {
  background-color: #353a42;
  color: #fff;
}
</style>
