<template>
  <div class="login-container">
    <el-row>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <div v-if="loginType == 0">
          <div class="login-img">
            <img class="img" :src="require('@/assets/imgs/logo1.png')" />
            <div>
              <el-dropdown @command="changeType">
                <div class="lange">
                  <img
                    :src="require('@/assets/imgs/icon_fanyi.png')"
                    class="mr10"
                  />
                  {{ $i18n.locale }}
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="CN">中文简体</el-dropdown-item>
                    <el-dropdown-item command="EN">English</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <el-form
            ref="form"
            class="login-form"
            :model="loginForm"
            :rules="loginRules"
            :hide-required-asterisk="true"
          >
            <div class="login-title">{{ translateTitle("数字银行") }}</div>
            <div class="title-tips">
              {{ translateTitle("登录") }}&nbsp;Mastercard
            </div>
            <!-- 登录 -->
            <div v-if="loginType == 0">
              <el-form-item
                prop="loginName"
                label-position="top"
                :label="translateTitle('用户名')"
              >
                <el-input
                  v-model.trim="loginForm.loginName"
                  onfocus="this.removeAttribute('readonly');"
                  :placeholder="translateTitle('请输入用户名')"
                  type="text"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="password" :label="translateTitle('密码')">
                <el-input
                  show-password
                  ref="password"
                  v-model="loginForm.password"
                  onfocus="this.removeAttribute('readonly');"
                  :placeholder="translateTitle('请输入密码')"
                  type="password"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="settingInfo.googleAuth"
                prop="googleValidateCode"
                :label="translateTitle('GoogleAuth 验证码')"
              >
                <el-input
                  v-model.trim="loginForm.googleValidateCode"
                  onfocus="this.removeAttribute('readonly');"
                  :placeholder="translateTitle('请输入GoogleAuth验证码')"
                  type="text"
                  clearable
                >
                </el-input>
              </el-form-item>
              <div class="login-box-btns">
                <el-button
                  :loading="loading"
                  type="primary"
                  @click="handleLogin('form')"
                >
                  {{ translateTitle("登录") }}
                </el-button>
              </div>
              <!-- <div class="login-bottom two-between">
                <span class="cp" @click="loginType = 1"
                  >{{ translateTitle("忘记密码") }}&nbsp;?
                </span>
                <div>
                  <span> {{ translateTitle("还没有账户") }}?&nbsp;&nbsp;</span>
                  <span class="base-color" @click="handleRegister">{{
                    translateTitle("免费注册")
                  }}</span>
                </div>
              </div> -->
            </div>
          </el-form>
        </div>
        <div v-if="loginType == 1">
          <SetPass @handleSonClick="handleSonClick" />
        </div>
      </el-col>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SetPass from "./components/setPass.vue";
import { translateTitle } from "@/utils/i18n";
import {
  login,
  getSetting,
  getLoginUser,
  loginUserMenus,
} from "@/api/login.js";
import local from "@/utils/local";
import Cookies from "js-cookie";
// import { mapGetters } from "vuex";
// 引入生成路由函数
import { createRouter } from "@/router";
import { mapActions } from "vuex";
export default {
  inject: ["reload"],
  components: { SetPass },
  data() {
    const validateUsername = (rule, value, callback) => {
      if ("" === value)
        callback(new Error(this.translateTitle("请输入用户名")));
      else callback();
    };
    const validatePassword = (rule, value, callback) => {
      if ("" === value) callback(new Error(this.translateTitle("请输入密码")));
      else callback();
    };
    const validateGoogleValidateCode = (rule, value, callback) => {
      if ("" === value)
        callback(new Error(this.translateTitle("请输入GoogleAuth验证码")));
      else callback();
    };
    return {
      loginForm: {
        loginName: "",
        password: "",
        googleValidateCode: "",
      },
      loginRules: {
        loginName: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: validateUsername,
          },
        ],
        password: [
          { required: true, trigger: "change", validator: validatePassword },
        ],
        googleValidateCode: [
          {
            required: true,
            trigger: "change",
            validator: validateGoogleValidateCode,
          },
        ],
      },
      passwordType: "password",
      loading: false,
      loginType: 0, //0登录1是找回密码
      settingInfo: {},
    };
  },
  computed: {
    // ...mapGetters(["tagWel", "menu"]),
  },
  watch: {},
  created() {
    //把后端公钥存入
    sessionStorage.setItem(
      "javaPublicKey",
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCEDu4KADff8wYpZBJ8O4PzPLoOO23M+MJg3RwOuihh1z39utQsaIDhheW05MbjSmo/kRWBm39iLKXth2MugcsBY7o1D+QRmjzr0ztY6sPwFIyr6uHXIcqj/QjFDkG34CTxvZGNek94g4tUG1kbmlUMRmC+wntIsNJVl4mkeqGR6QIDAQAB"
    );
  },
  mounted() {
    this.getSettingInfo();
  },
  methods: {
    translateTitle,
    ...mapActions({
      changeLanguage: "settings/changeLanguage",
    }),
    //点击子组件传值
    handleSonClick(data) {
      this.loginType = data;
    },
    //免费注册
    handleRegister() {
      this.$router.push("/register");
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    //登录
    handleLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.loginForm)
            .then(async (res) => {
              const data = res.data;
              if (data.code == 200) {
                local.set("tk", data.data.token);
                sessionStorage.setItem("loginName", this.loginForm.loginName);
                //获取菜单
                let res1 = await loginUserMenus();
                await createRouter(res1.data.data); // 调用生成路由函数
                //获取个人信息
                let info = await getLoginUser();
                local.set("userInfo", info.data.data);
                this.loading = false;
                let menu = local.get("menus");
                let welpath =
                  menu[0].children.length > 0 && menu[0].children[0].path != ""
                    ? menu[0].children[0].path
                    : menu[0].path;
                console.log("welpath===welpath", welpath);
                this.$router.push(welpath);
                // this.$router.push("/home");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    //获取后台信息接口设置
    async getSettingInfo() {
      let res = await getSetting();
      this.settingInfo = res.data.data;
      Cookies.set("commonInfo", JSON.stringify(res.data.data));
    },
    changeType(command) {
      this.changeLanguage(command);
      this.$i18n.locale = command;

      this.reload();
      // document.title = getPageTitle(this.$route.meta.title)
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  height: 100vh;
  width: 100%;
  background: url("../../assets/imgs/bg1.png") center no-repeat;
  background-size: 100% 100%;
}
.login-img {
  // margin-top: 30px;
  // margin-left: 50px;
  margin: 50px 0 0 0px;
  display: flex;
  align-items: center;
  .lange {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    font-family: DIN;
    font-weight: 500;
    color: #000000;
    margin-left: 10px;
  }
  .img {
    width: 100px;
  }
}

.login-form {
  position: relative;
  max-width: 100%;
  padding: 2.5vh 1.5vh;
  margin: calc((100vh - 840px) / 2) 2vw 8vw;
  // background-color: #fff;
  border-radius: 8px;
  width: 540px;
  .login-title {
    font-size: 44px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #020202;
    margin-bottom: 100px;
  }

  .title-tips {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #31363e;
    margin-bottom: 30px;
    position: relative;
    z-index: 999999;
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      left: -14px;
      width: 147px;
      height: 19px;
      background: #96baed;
      border-radius: 10px;
      opacity: 0.7;
      z-index: -1;
    }
  }

  // i {
  //   position: absolute;
  //   top: 8px;
  //   left: 5px;
  //   font-size: 16px;
  //   color: #d7dee3;
  //   cursor: pointer;
  //   user-select: none;
  // }

  ::v-deep {
    .el-form-item {
      padding-right: 0;
      margin: 20px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
    }

    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }

    .el-input {
      box-sizing: border-box;

      input {
        height: 48px;
        // padding-left: 35px;
        line-height: 58px;
        background: #fff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        border: 2px solid #eef0f6;
      }
    }
  }
  .login-box-btns {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    .el-button {
      flex: 1;
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
      border-color: #353a42;
      background: #353a42;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // &:hover {
      //   opacity: 0.8;
      //   color: #fff;
      // }
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .svg-container {
    i {
      line-height: 30px;
    }
  }
  .img-code {
    position: absolute;
    top: 1px;
    right: 2px;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .img-code1 {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    cursor: pointer;
  }
}
.login-bottom {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a6b5c4;
  text-align: right;
}
</style>
<style scoped>
.el-dropdown-menu {
  background-color: #e5e4e1 !important;
  border: none;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #383333;
  background-color: #fffdf5 !important;
  border-radius: 6px;
}
</style>
<style>
/* 消除小三角 */
.el-popper[x-placement^="bottom"] .popper__arrow {
  border: none !important;
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border: none !important;
}
</style>
