<template>
  <!-- 分页 -->
  <div
    v-show="total > 0"
    :class="{ center: center, 'pagination-container': true, 'pad-0': small }"
  >
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :total="total"
      :small="small"
      :layout="layout"
      :page-sizes="pageSizes"
      :background="background"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",

  props: {
    // 总数
    total: {
      required: true,
      type: Number,
    },
    // 当前页
    page: {
      type: Number,
      default: 1,
    },
    // 每页显示条数
    limit: {
      type: Number,
      default: 10,
    },
    // 每页显示条数筛选列表
    pageSizes: {
      type: Array,
      default() {
        return [500, 100, 50, 20, 10];
      },
    },
    // 功能
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    // 背景
    background: {
      type: Boolean,
      default: true,
    },
    // 是否居中
    center: {
      type: Boolean,
      default: false,
    },
    // 小型分页
    small: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    total(val) {
      let page = this.page;
      const newPage = Math.ceil(val / this.limit) || 1;

      if (page > newPage) {
        page--;
        this.handleCurrentChange(page);
      }
    },
  },

  methods: {
    // 每页条数改变
    handleSizeChange(val) {
      this.$emit("update:limit", val);
      this.$emit("pagination", { page: this.page, limit: val });
    },

    // 当前页改变
    handleCurrentChange(val) {
      this.$emit("update:page", val);
      this.$emit("pagination", { page: val, limit: this.limit });
    },
  },
};
</script>

<style lang="less" scoped>
/** 表格布局 **/
.pagination-container {
  display: flex;
  padding: 15px 20px;
  background: #fff;
}
.center {
  justify-content: center;
}
.pagination-container .el-pagination {
  margin-left: auto;
  font-weight: normal;
}
.pagination-container.center .el-pagination {
  margin: 0 auto;
}
.pad-0 {
  padding: 0;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #F2F7F9;
    color: #000;
  }

  // .el-pagination.is-background .el-pager li:not(.disabled):hover {
  //   color: #81ccf1;
  // }
.el-select .el-input.is-focus .el-input__inner {
    border-color: #F2F7F9;
}
.el-pagination__sizes .el-input .el-input__inner:hover {
    border-color: #F2F7F9;
}
}
</style>
<style>

   .el-select-dropdown__item.selected {
    color: #000 !important;
    font-weight: 500 !important;
}

</style>
