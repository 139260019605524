<template>
  <div class="layout">
    <!-- 导航 -->
    <left-nav></left-nav>

    <!-- 内容 -->
    <div class="content">
      <!-- 头部 -->
      <!-- <right-header></right-header> -->
      <!-- Layout的子组件 渲染出来的位置 -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// 引入组件
import LeftNav from "./LeftNav.vue";

export default {
  // 注册
  components: {
    LeftNav,
  },
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  display: flex;
  .content {
    flex: 1;
    background-color: #fff;
    height: calc(100% - 20px);
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #eee;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    // padding: 20px 30px;
    // .out {
    //   padding: 20px;
    //   background-color: #f0f2f5;
    //   flex: 1;
    //   overflow-y: scroll;
    // }
  }
}
</style>
