/**
 * ajax工具函数
 */
import axios from "axios";
import { Message } from "element-ui";
import local from "@/utils/local";
import router from "@/router"; // 引入路由 自动找到index.js
import JSEncrypt from "jsencrypt";

const bb = new JSEncrypt({ default_key_size: 1024 });
const rsaUtil = {
  //RSA 位数，这里要跟后端对应
  bits: 1024,

  //当前JSEncrypted对象
  thisKeyPair: {},

  //生成密钥对(公钥和私钥)
  genKeyPair: function (bits = rsaUtil.bits) {
    let genKeyPair = {};

    //获取私钥
    genKeyPair.privateKey = bb.getPrivateKey();

    //获取公钥
    genKeyPair.publicKey = bb.getPublicKey();

    return genKeyPair;
  },

  //公钥加密
  encrypt: function (plaintext, publicKey) {
    if (plaintext instanceof Object) {
      //1、JSON.stringify
      plaintext = JSON.stringify(plaintext);
    }
    publicKey && bb.setPublicKey(publicKey);
    return bb.encrypt(plaintext);
  },

  //私钥解密
  decrypt: function (ciphertext, privateKey) {
    privateKey && bb.setPrivateKey(privateKey);
    let decString = bb.decrypt(ciphertext);
    if (decString.charAt(0) === "{" || decString.charAt(0) === "[") {
      //JSON.parse
      decString = JSON.parse(decString);
    }
    return decString;
  },
};

// 设置统一的服务器地址
axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? 'http://testmanger.finecodb.org/manager' : 'http://testmanger.finecodb.org/manager';
axios.defaults.headers["Content-Type"] = "application/json";

// 请求拦截器 interceptors
axios.interceptors.request.use(
  (config) => {
    // 统一带令牌
    let token = local.get("tk");

    if (token) {
      const name = sessionStorage.getItem("loginName");
      const sign = rsaUtil.encrypt(
        `${name},P8J5SqvqRv0icxaK,${new Date().getTime()}`,
        sessionStorage.getItem("javaPublicKey")
      );
      // Authorization
      config.headers.manageUserToken = token;
      config.headers.manageSign = sign;
    }
    config.data = JSON.stringify(config.data);
    return config;
  },
  (err) => {
    return Promise.reject(err); // 让Promise状态从进行中 -> 失败
  }
);

// 响应拦截器 interceptors ( 接收到数据之前 )
axios.interceptors.response.use(
  (response) => {
    // 如果后端响应的数据 有 code 且 有 msg
    if (response.data.code !== undefined && response.data.msg !== undefined) {
      let { code, msg } = response.data; // 取出code和msg
      switch (code) {
        case 401:
          router.push({ path: "/login", replace: true });
          break;
      }
    }

    return response;
  },
  (err) => {
    // 如果错误码是401 证明未授权 没有权限
    if (err.response.status === 401) {
      // 直接跳转到登录
      router.push({ path: "/login", replace: true });
    }

    return Promise.reject(err); // 让Promise状态从进行中 -> 失败
  }
);

// 暴露出去
export default axios;
