export default {
  //路由相关
  router: {},
  vabI18n: {
    系统菜单根节点:"系统菜单根节点",
    首页: "首页",
    //设置
    后台设置: "后台设置",
    菜单管理: "菜单管理",
    角色管理: "角色管理",
    权限管理: "权限管理",
    权限: "权限管理",
    // saas管理
    SaaS管理: "SaaS 管理",
    客户管理: "客户管理",
    客户数据统计: "客户数据统计",
    //数据统计
    每日充值: "每日充值",
    每日转账: "每日转账",
    每日买币: "每日买币",
    每日卖币: "每日卖币",
    每日提现: "每日提现",
    全网统计: "全网统计",
    //银行卡
    银行卡管理: "银行卡管理",
    系统设置: "系统设置",
    // 交易管理
    交易管理: "交易管理",
    充值: "充值",
    提现: "提现",
    转账: "转账",
    买币: "买币",
    卖币: "卖币",
    币种管理: "币种管理",
    //用户管理
    用户管理: "用户管理",
    节点: '节点',
    申请审核: '申请审核',
    用户查询: "用户查询",
    用户列表: "用户列表",
    KYC管理: "KYC管理",
    确定: "确定",
    取消: "取消",
    操作: "操作",
    用户名: "用户名",
    密码: "密码",
    保存: "保存",
    编辑: "编辑",
    更新: "更新",
    删除: "删除",
    永久: "永久",
    过期: "过期",
    消息: "消息",
    邮箱: "邮箱",
    电话: "电话",
    查询: "查询",
    更多: "更多",
    编辑: "编辑",
    启动: "启动",
    启用: "启用",
    禁用: "禁用",
    重置密码: "重置密码",
    重置Google: "重置Google",
    登录: "登录",
    忘记密码: "忘记密码",
    还没有账户: "还没有账户",
    请输入用户名: "请输入用户名",
    免费注册: "免费注册",
    输入邮箱: "输入邮箱",
    请输入邮箱: "请输入邮箱",
    邮箱验证码: "邮箱验证码",
    请输入邮箱验证码: "请输入邮箱验证码",
    请输入密码: "请输入密码",
    重设密码: "重设密码",
    输入手机号码: "输入手机号码",
    验证码: "验证码",
    请输入新密码: "请输入新密码",
    请输入手机号: "请输入手机号",
    请输入短信验证码: "请输入短信验证码",
    发送验证码: "发送验证码",
    重新发送: "重新发送",
    我同意本网站的: "我同意本网站的",
    条款和协议: "条款和协议",
    已有账号: "已有账号",
    快速登录: "快速登录",
    新增: "新增",
    原因: "原因",
    GoogleAuth验证码: "GoogleAuth 验证码",
    请输入GoogleAuth验证码: "请输入GoogleAuth验证码",
    此操作将永久删除: "此操作将永久删除该文件, 是否继续",
    提示: "提示",
    删除成功: "删除成功",
    已取消删除: "已取消删除",
    操作成功: "操作成功",
    请选择: "请选择",
    是: "是",
    否: "否",
    保存更改: "保存更改",
    请输入: "请输入",
    抱歉: "抱歉!",
    当前页面不存在: "当前页面不存在...",
    请检查您输入的网址是否正确:
      "请检查您输入的网址是否正确，或点击下面的按钮返回首页。",
    返回首页: "返回首页",
    //sass
    管理: "管理",
    客户列表: "客户列表",
    客户名: "客户名",
    Logo: "Logo",
    Google验证码: "Google验证码",
    状态: "状态",
    创建时间: "创建时间",
    新增客户: "新增客户",
    今日数据: "今日数据",
    启动: "启动",
    禁用: "禁用",
    输入名称: "输入名称",
    输入邮箱: "输入邮箱",
    输入密码: "输入密码",
    退出: "退出",
    退出成功: "退出成功",
    //首页
    累计数据: "累计数据",
    累计用户: "累计用户",
    月: "月",
    日: "日",
    周: "周",
    累计账户余额: "累计账户余额",
    累计账户充值: "累计账户充值",
    累计账户消费: "累计账户消费",
    累计加密账户提现: "累计加密账户提现",
    累计加密账户余额: "累计加密账户余额",
    累计加密账户充值: "累计加密账户充值",
    新增用户: "新增用户",
    账户充值: "账户充值",
    账户消费: "账户消费",
    加密账户充值: "加密账户充值",
    加密账户提现: "加密账户提现",
    //后台设置
    菜单树: "菜单树",
    您的交易: "您的交易",
    上级节点: "上级节点",
    节点名称: "节点名称",
    节点路径: "节点路径",
    同级排序权重: "同级排序权重",
    权限管理: "权限管理",
    用户列表: "用户列表",
    权限名称: "权限名称",
    权限描述: "权限描述",
    授权: "授权",
    操作表单: "操作表单",
    权限名称ROLE_开头全大写: "权限名称,ROLE_开头,全大写",
    权限内容可访问的url: "权限内容,可访问的url,多个时用,隔开",
    授权成功: "授权成功",
    角色设置: "角色设置",
    查询登录名: "查询登录名",
    登录名: "登录名",
    重制密码: "重制密码",
    初始重置密码: "初始、重置密码",
    是否允许登录系统: "是否允许登录系统（软删除）",
    是否允许多人在线: "是否允许多人在线",
    限制允许登录的IP集合: "限制允许登录的IP集合",
    限制允许登录的IP几何: "限制允许登录的IP几何（多个IP用英文逗号隔开）",
    账号失效时间: "账号失效时间",
    最近修改密码时间: "最近修改密码时间",
    更新时间: "更新时间",
    请输入登录名: "请输入登录名",
    请输入登录密码: "请输入登录密码",
    请选择用户类型: "请选择用户类型",
    请选择用户角色: "请选择用户角色",
    用户名称: "用户名称",
    重置成功: "重置成功!",
    重置失败: "重置失败,请重试!",
    //数据统计
    每日充值: "每日充值",
    充值数据: "充值数据",
    代币名称: "代币名称",
    金额: " 金额",
    买币数据: "买币数据",
    卖币数据: "卖币数据",
    充值提现: "充值提现",
    转账数据: "转账数据",
    全网统计: "全网统计",
    统计数据: "统计数据",
    充值: "充值",
    提现: "提现",
    转账: "转账",
    买币: "买币",
    卖币: "卖币",
    //银行卡管理
    银行卡管理: "银行卡管理",
    银行卡列表: "银行卡列表",
    银行卡: "银行卡",
    创建: "创建",
    激活: "激活",
    分配: "分配",
    银行卡号: "银行卡号",
    卡片类型: "卡片类型",
    卡片状态: "卡片状态",
    请输入名称: "请输入名称",
    请选择一条数据操作: "请选择一条数据操作",
    请选择需要操作的数据: "请选择需要操作的数据",
    物理卡卡号: "物理卡卡号",
    虚拟卡: "虚拟卡",
    物理卡: "物理卡",
    //系统设置
    系统设置: "系统设置",
    安全码设置: "安全码设置",
    Google验证器: "Google验证器",
    重制安全码: "重制安全码",
    设置安全码: "设置安全码",
    修改谷歌验证器: "修改谷歌验证器",
    绑定谷歌验证器: "绑定谷歌验证器",
    新安全码: "新安全码",
    输入安全码: "输入安全码",
    请输入6位数字: "请输入6位数字",
    重复输入新安全码: "重复输入新安全码",
    重复输入安全码: "重复输入安全码",
    安全验证: "安全验证",
    请输入验证码: "请输入验证码",
    请妥善备份私钥以防遗失: "请妥善备份私钥以防遗失",
    地址: "地址",
    请输入安全验证码: "请输入安全验证码",
    验证码不能为空: "验证码不能为空",
    两次验证码必须一致: "两次验证码必须一致",
    修改安全码: "修改安全码",
    已发送请注意查收: "已发送，请注意查收",
    请选择安全验证方式: "请选择安全验证方式!",
    复制成功: "复制成功!",
    复制失败: "复制失败!",
    修改电话号码: "修改电话号码",
    绑定电话号码: "绑定电话号码",
    邮箱认证: "邮箱认证",
    修改邮箱: "修改邮箱",
    绑定邮箱: "绑定邮箱",
    输入电话号码: "输入电话号码",
    请输入手机号国家代码: "请输入手机号国家代码",
    请输入电话号码: "请输入电话号码",
    //交易管理
    交易管理: "交易管理",
    充值列表: "充值列表",
    交易类型: "交易类型",
    充值地址: "充值地址",
    交易Hash: "交易Hash",
    时间: "时间",
    提现列表: "提现列表",
    到账地址: "到账地址",
    通过: "通过",
    不通过: "不通过",
    转账列表: "转账列表",
    到账账户: "到账账户",
    交易列表: "交易列表",
    币种管理: "币种管理",
    代币LOGO: "代币LOGO",
    合约地址: "合约地址",
    代币小数位: "代币小数位",
    CoBo名称: "CoBo名称",
    处理中: "处理中",
    成功: "成功",
    失败: "失败",
    // 用户管理
    用户查询: "用户查询",
    用户信息: "用户信息",
    加密账户: "加密账户",
    累计充值: "累计充值",
    累计提现: "累计提现",
    Google验证: "Google验证",
    累计转账: "累计转账",
    重制: "重制",
    账户: "账户",
    标题: "标题",
    名字: "名字",
    中间名字: "中间名字",
    移动国家代码: "移动国家代码",
    出生日期: "出生日期",
    电子邮件: "电子邮件",
    电话号码: "电话号码",
    国籍: "国籍",
    用户姓氏: "用户姓氏",
    性别: "性别",
    证件类型: "证件类型",
    KYC管理: "KYC管理",
    KYC全部: "KYC全部",
    地址证明: "地址证明",
    身份证明: "身份证明",
    未完成: "未完成",
    完成: "完成",
    用户电话: "用户电话",
    审核状态: "审核状态",
    填写原因: "填写原因",
    KYC操作: "KYC操作",
    未审核: "未审核",
    KYC身份编辑: "KYC身份编辑",
    身份证: "身份证",
    输入证件号码: "输入证件号码",
    证件号码:"证件号码",
    证件发行国家: "证件发行国家",
    过期时间: "过期时间",
    选择过期时间: "选择过期时间",
    文件上传: "文件上传",
    上传文件图片只能是图片格式: "上传文件图片只能是图片格式!",
    后台首页: "后台首页",
    数据统计: "数据统计",
    开始日期: "开始日期",
    结束日期: "结束日期",
    数字银行: "数字银行万事达卡",
    数据统计: "数据统计",
    请输入手机号验证码:"请输入手机号验证码",
    保存失败:"保存失败!",
    加密异常:"加密异常!",
    解密异常:"解密异常!",
    请求失败:"请求失败!",
    用户名或密码错误:"用户名或密码错误",
    参数错误:"参数错误",
    用户登录名已存在:"用户登录名已存在",
    角色名开头为ROLE_:"角色名开头为ROLE_",
    旧密码错误:"旧密码错误",
    Google验证码错误:"Google验证码错误",
    邮箱已存在:"邮箱已存在",
    只有租户能新增客户:"只有租户能新增客户",
    Stylopay用户不存在:"Stylopay用户不存在",
    Stylopay账户不存在:"Stylopay账户不存在",
    stylopay接口失败:"stylopay接口失败",
    安全码错误:"安全码错误",
    验证码错误:"验证码错误",
    重置安全码错误:"重置安全码错误",
    请求的数据格式不符:"请求的数据格式不符!",
    登录凭证过期:"登录凭证过期!",
    抱歉你无权访问:"抱歉，你无权访问，请联系管理员!",
    你访问的资源不存在:"抱歉，你访问的资源不存在!",
    服务器内部错误请联系管理员:"抱歉，服务器内部错误，请联系管理员!",
    服务器正忙请稍后再试:"抱歉，服务器正忙，请稍后再试!",
    请输入物理卡号:"请输入物理卡号",
    至:"至",
    用户类型:"用户类型",
    用户角色:"用户角色",
    重置验证码:"重置验证码",
    申请列表: '申请列表',
    申请数据:'申请数据',
    节点类型: '节点类型',
    国家: '国家',
    申请人名称: '申请人名称',
    申请邮箱: '申请邮箱',
    邀请人: '邀请人',
    联系方式: '联系方式',
    审核状态: '审核状态',
    审核通过: '审核通过',
    审核不通过: '审核不通过',
    联合创始人: '联合创始人',
    特约合伙人: '特约合伙人',
    超级节点: '超级节点',
    国际节点: '国际节点',
    社区节点: '社区节点',
    待审核: '待审核',
    虚拟节点:'虚拟节点',
    真实节点:'真实节点',
    是否设置为虚拟节点:'是否设置为虚拟节点?',
    是否设置为真实节点:'是否设置为真实节点?',
    节点状态:'节点状态',
    分红配置:'分红配置',
    划转:'划转',
    类型:'类型',
    资金池总额:'资金池总额',
    分红币种:'分红币种',
    是否开启:'是否开启',
    划转列表:'划转列表',
    业务编号:'业务编号',
    用户邮箱:'用户邮箱',
    银行账户:'银行账户',
    手续费:'手续费',
    银行交易编号:'银行交易编号',
    业务状态:'业务状态',
    请选择业务类型:'请选择业务类型',
    加密到银行:'加密到银行',
    银行到加密:'银行到加密',
    用户ID:'用户ID',
    团队总人数:'团队总人数',
    直推人数:'直推人数',
    此操作将提交到银行是否继续:'此操作将提交到银行, 是否继续?',
    是否拒绝:'是否拒绝?',
    已取消提交:'已取消提交!',
    手续费分红:'手续费分红',
    生态池分红:'生态池分红',
    提交到银行:'提交到银行',
    拒绝:'拒绝',
    今日新增用户:'今日新增用户',
    昨日:'昨日',
    交易用户:'交易用户',
    今日:'今日',
    总用户量:'总用户量',
    本月新增:'本月新增',
    今日托管数额:'今日托管数额',
    今日加密货币交易数额:'今日加密货币交易数额',
    今日银行交易数额:'今日银行交易数额',
    账户余额:'账户余额',
    异常信息:'异常信息',
    用户节点:'用户节点',
    用户数据:'用户数据',
    本月已完成团队业绩:'本月已完成团队业绩',
    连续三月业绩完成状态:'连续三月业绩完成状态',
    未分红:'未分红',
    本月业绩是否完成:'本月业绩是否完成',
    本月需完成团队业绩:'本月需完成团队业绩',
    已赠送ERA:'已赠送ERA',
    价值:'价值',
    应赠送总金额:'应赠送总金额',
    停用启用:'停用启用',
    团队等级:'团队等级',
    业绩查询:'业绩查询',
    停用:'停用',
    日期:'日期',
    是否完成:'是否完成',
    需要完成的业绩:'需要完成的业绩',
    已完成的业绩:'已完成的业绩',
    节点配置:'节点配置',
    配置数据:'配置数据',
    总名额:'总名额',
    剩余名额:'剩余名额',
    全网手续费分红比例:'全网手续费分红比例',
    全网生态分红比例:'全网生态分红比例',
    每月需要完成的业绩:'每月需要完成的业绩',
    已获得名额:'已获得名额',
    赠送金额:'赠送金额',
    赠送的银行卡名称:'赠送的银行卡名称',
    申请金额:'申请金额',
    等级:'等级',
    公告:'公告',
    公告列表:'公告列表',
    公告数据:'公告数据',
    阿拉伯文标题:'阿拉伯文标题',
    阿拉伯文内容:'阿拉伯文内容',
    中文标题:'中文标题',
    中文内容:'中文内容',
    英文标题:'英文标题',
    英文内容:'英文内容',
    意大利语标题:'意大利语标题',
    意大利语内容:'意大利语内容',
    日文标题:'日文标题',
    日文内容:'日文内容',
    韩语标题:'韩语标题',
    韩语内容:'韩语内容',
    俄罗斯标题:'俄罗斯标题',
    俄罗斯内容:'俄罗斯内容',
    泰语标题:'泰语标题',
    泰语内容:'泰语内容',
    繁体中文标题:'繁体中文标题',
    繁体中文内容:'繁体中文内容',
    越南语标题:'越南语标题',
    越南语内容:'越南语内容',
    印尼标题:'印尼标题',
    印尼内容:'印尼内容',
    修改:'修改',
    发布:'发布',
    新增公告:'新增公告',
    资产:'资产',
    钱包明细:'钱包明细',
    托管:'托管',
    预收益记录:'预收益记录',
    用户钱包:'用户钱包',
    钱包列表:'钱包列表',
    业务类型:'业务类型',
    用户的walletAddress:'用户的walletAddress',
    汇率:'汇率',
    主网:'主网',
    银行USD金额:'银行USD金额',
    托管金额:'托管金额',
    托管类型:'托管类型',
    链名称:'链名称',
    币种名称:'币种名称',
    是否赎回:'是否赎回',
    复投:'复投',
    复利:'复利',
    已赎回:'已赎回',
    未赎回:'未赎回',
    预收益列表:'预收益列表',
    托管编号:'托管编号',
    收益:'收益',
    托管类型:'托管类型',
    已累计到本金:'已累计到本金',
    托管:'托管',
    领取编号:'领取编号',
    释放比例:'释放比例',
    领取奖励:'领取奖励',
    已领取:'已领取',
    未领取:'未领取',
    托管列表:'托管列表',
    请选择状态:'请选择状态',
    备注:'备注',
    银行卡转账:'银行卡转账',
    消费:'消费',
    转入:'转入',
    服务费:'服务费',
    充值银行:'充值银行',
    充值加密货币:'充值加密货币',
    托管:'托管',
    托管赎回:'托管赎回',
    直推奖励:'直推奖励',
    平级奖励:'平级奖励',
    奖金分红:'奖金分红',
    贷款保证金:'贷款保证金',
    贷款:'贷款',
    还款:'还款',
    退还保证金:'退还保证金',
    复利收益:'复利收益',
    托管收益:'托管收益',
    团队奖励:'团队奖励',
    越级奖励:'越级奖励',
    闪兑:'闪兑',
    手续费分红:'手续费分红',
    生态池分红:'生态池分红',
    节点赠送:'节点赠送',
    退款:'退款',
    用户关系:'用户关系',
    用户ID:'用户ID',
    请输入ID或Email:'请输入ID或Email',
    用户ID或EMAIL:'用户ID或EMAIL',
    用户昵称:'用户昵称',
    COBO名称:'COBO名称',
    余额:'余额',
    奖励来源:'奖励来源',
    银行明细:'银行明细',
    账户ID:'账户ID',
    联系人ID:'联系人ID',
    交易金额:'交易金额',
    交易状态:'交易状态',
    是否同步:'是否同步',
    已同步:'已同步',
    未同步:'未同步',
    手续费列表:'手续费列表',
    币种ID:'币种ID',
    固定手续费:'固定手续费',
    最少数量:'最少数量',
    手续费比例:'手续费比例',
    百分比或固定值:'百分比或固定值',
    使用百分比:'使用百分比',
    使用固定值:'使用固定值',
    币种链:'币种链',
    用户编号:'用户编号',
    银行余额:'银行余额',
    钱包余额:'钱包余额',
    节点卡号:'节点卡号',
    卡号数据:'卡号数据',
    导入:'导入',
    卡号:'卡号',
    使用人:'使用人',
    名称:'名称',
    是否使用:'是否使用',
    上传:'上传',
    点击上传:'点击上传',
    卡类型:'卡类型',
    发布状态:'发布状态',
    未发布:'未发布',
    发布中:'发布中',
    已发布:'已发布',
    轮播图:'轮播图',
    轮播列表:'轮播列表',
    图片路径:'图片路径',
    语言:'语言',
    轮播图类型:'轮播图类型',
    请选择语言:'请选择语言',
    请选择轮播图类型:'请选择轮播图类型',
    替换或追加:'替换或追加',
    替换所有:'替换所有',
    追加:'追加',
    新增轮播图:'新增轮播图',
    编辑轮播图:'编辑轮播图',
    排序:'排序',
    请排序:'请排序',
    设置:'设置',
    参数设置:'参数设置',
    参数列表:'参数列表',
    键:'键',
    值:'值',
    描述:'描述',
    输入键:'输入键',
    输入值:'输入值',
    输入描述:'输入描述',
    联系人:'联系人',
    联系人列表:'联系人列表',
    联系人银行ID:'联系人银行ID',
    联系人邮箱:'联系人邮箱',
    联系人名称:'联系人名称',
    标记等级:'标记等级',
    修改等级:'修改等级',
    钱包排序:'钱包排序',
    月化收益率:'月化收益率',
    走势图路径:'走势图路径',
    充值到银行卡:'充值到银行卡',
    银行卡到加密:'银行卡到加密',
    每日复利收益比例:'每日复利收益比例',
    最大天数:'最大天数',
    最小天数:'最小天数',
    托管赎回手续费:'托管赎回手续费',
    托管每日收益比例:'托管每日收益比例',
    允许:'允许',
    不允许:'不允许',
    链名:'链名',
    输入链名:'输入链名',
    币种logo:'币种logo',
    输入币种名称:'输入币种名称',
    输入COBO名称:'输入COBO名称',
    是否闪兑:'是否闪兑',
    请选择是否闪兑:'请选择是否闪兑',
    走势GIF图地址:'走势GIF图地址',
    请选择加密到银行:'请选择加密到银行',
    请选择银行到加密:'请选择银行到加密',
    小数位数:'小数位数',
    输入小数位数:'输入小数位数',
    钱包列表排序:'钱包列表排序',
    输入钱包列表排序:'输入钱包列表排序',
    托管月化收益率:'托管月化收益率',
    输入托管月化收益率:'输入托管月化收益率',
    托管排序:'托管排序',
    输入托管排序:'输入托管排序',
    是否允许托管:'是否允许托管',
    可以:'可以',
    不可以:'不可以',
    不可以闪兑:'不可以闪兑',
    请选择等级:'请选择等级',
    链:'链',
    代币:'代币',
    邀请奖池:'邀请奖池',
    邀请奖池列表:'邀请奖池列表',
    是否内部人员:'是否内部人员',
    邀请人数:'邀请人数',
    轮数:'轮数',
    是否启用:'是否启用',
    黑名单:'黑名单',
    黑名单列表:'黑名单列表',
    昵称:'昵称',
    邮箱或ID:'邮箱或ID',
    业绩查询列表:'业绩查询列表',
    业绩查询:'业绩查询',
    团队业绩:'团队业绩',
    个人托管:'个人托管',
	邀请人数必须大于等于:'邀请人数必须大于等于',
	人:'人',
    冻结资金:'冻结资金',
	冻结资金列表:'冻结资金列表',
	剩余金额:'剩余金额',
	冻结金额:'冻结金额',
	输入冻结金额:'输入冻结金额',
	Mastercard充值手续费:'Mastercard充值手续费',
	Mastercard月费:'Mastercard月费',
	Mastercard转账手续费:'Mastercard 转账手续费',
	Mastercard提现费用:'Mastercard提现费用',
	Mastercard虚拟账户费用:'Mastercard虚拟账户费用',
	Mastercard外汇费用:'Mastercard外汇费用',
  },
};
