<template>
  <div class="left-nav">
    <div class="left-content">
      <div class="header">
        <img
          class="left-nav-logo"
          width="80"
          src="../../assets/imgs/icon_mastergo@2x.png"
          alt="logo"
        />
      </div>

      <!-- 导航 -->
      <div class="menu-list">
        <el-menu
          :collapse="isCollapse"
          background-color="#304156"
          text-color="#fff"
          unique-opened
          router
          :default-active="curActive"
        >
          <template v-for="menu in menus">
            <!-- 单个 -->
            <el-menu-item
              :index="menu.path"
              :key="menu.path"
              v-if="menu.children.length === 1 && menu.children[0].path == ''"
            >
              <span slot="title">
                <img :src="menu.meta.icon" />
                <span> {{ translateTitle(menu.meta.title) }}</span>
              </span>
            </el-menu-item>
            <!-- 有二级的 -->
            <el-submenu :index="menu.path" :key="menu.path" v-else>
              <template slot="title">
                <img :src="menu.meta.icon" class="mr10" />
                <span>{{ translateTitle(menu.meta.title) }}</span>
              </template>
              <el-menu-item
                v-for="v in menu.children"
                :key="v.path"
                :index="v.path"
              >
                <span class="null-img"></span>
                {{ translateTitle(v.meta.title) }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import { translateTitle } from "@/utils/i18n";
export default {
  // 数据
  data() {
    return {
      // 是否折叠
      isCollapse: document.body.clientWidth < 1000 ? true : false,
      menus: [], // 菜单
    };
  },

  // 方法
  methods: {
    translateTitle,
    // 适配导航
    fitNav() {
      // 监听窗口大小变化
      window.addEventListener("resize", () => {
        if (document.body.clientWidth < 1000) {
          // 如果宽度小于1000 就收起来
          this.isCollapse = true; // 让折叠变成true -> 要折叠 -> 收起来
        } else {
          this.isCollapse = false; // 让折叠变成false 就是不折叠了 -> 展开
        }
      });
    },
  },

  // 生命周期
  created() {
    this.menus = local.get("menus");
    // 调用
    this.fitNav();

    // 导航的生命周期 一进来就监听一个自定义事件 等待被触发
    this.$bus.$on("change-collapse", (data) => {
      // 修改自己的折叠状态 让导航的状态和头部的
      // 一起关联
      this.isCollapse = data;
    });
  },

  // 计算属性
  computed: {
    // 当前激活
    curActive() {
      return this.$route.path; // /total/order
    },
  },
};
</script>

<style lang="less" scoped>
.left-nav {
  width: 220px;
  height: 100%;
  background-color: #f9fbfc;
  .left-content {
    margin-right: -6px;
  }
  .header {
    height: 80px;
    line-height: 80px;
    padding-left: 20px;
    width: 220px;
    display: flex;
    align-items: center;
    color: #000;
    background-color: #f9fbfc;
  }
  .menu-list {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
  }
  ::v-deep {
    .el-menu {
      border: none;
    }
    .el-menu-item {
      background-color: #f9fbfc !important;
      color: #31363e !important;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      &:hover {
        background: #e5f1f6 !important;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #31363e !important;
      }
    }
    .el-submenu__title {
      background: #f9fbfc !important;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #31363e !important;
    }
    .is-active {
      background: #e5f1f6 !important;
      color: #31363e !important;
      // .el-submenu__title {
      //   background: #e5f1f6 !important;
      //   font-size: 18px;
      //   font-family: Microsoft YaHei;
      //   font-weight: bold;
      //   color: #31363e !important;
      // }
    }
  }
  .null-img {
    width: 20px;
    display: inline-block;
  }
  /*隐藏文字*/
  .el-menu--collapse .el-submenu__title span {
    display: none;
  }
  /*隐藏 > */
  .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }
}
</style>
